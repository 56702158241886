.btn[data-v-ca9decc4] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.shutleBack[data-v-ca9decc4] {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.shutleTitle[data-v-ca9decc4] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent[data-v-ca9decc4] {
  height: 500px;
  overflow: auto;
}
[data-v-ca9decc4] .departTable .avue-crud .el-table {
  height: calc(100vh - 441px) !important;
  max-height: calc(100vh - 441px) !important;
}